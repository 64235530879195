import React from 'react'

const About = () => {
  return (
    <>
      <div className="about-container">
        <h2>VÄLKOMNA</h2>
        <p className="myP">
          Vi på DrinkAmbulansen är ett gäng proffsiga Bartenders som brinner för
          service i alla dess former. Tillsammans har vi mer än 100 års
          erfarenhet av att göra människor nöjda med både mat och dryck.
          Födelsedagar, Bröllop, Student, eller oavsett vilken form av
          tillställning, privat eller företag, så är vi den absolut bästa
          investeringen för att göra eran kväll oförglömlig.
        </p>{' '}
        <p className="myP">
          {' '}
          Vi tillhandahåller all materiel för baren och hjälper till med allt
          som har med festen att göra samt idéer och förslag på drinkar och det
          som kommer göra kvällen unik.
        </p>{' '}
        <p className="myP">
          Vi på DrinkAmbulansen ser fram imot att få göra just din kväll
          fantastisk.
        </p>
      </div>
    </>
  )
}

export default About
