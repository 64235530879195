import color from './Assets/color.jpg'
import grape from './Assets/grape.jpg'
import jack from './Assets/jack.jpg'
import negroni from './Assets/negroni.jpg'
import sours from './Assets/sours.jpg'
import sours1 from './Assets/sours1.jpg'
import cocktails from './Assets/cocktails.jpg'
import event from './Assets/event.jpg'
import bloodymary from './Assets/bloodymary.jpg'

export const images = [
  { img: grape },
  { img: jack },
  { img: negroni },
  { img: color },
  { img: grape },
  { img: sours },
  { img: sours1 },
  { img: cocktails },
  { img: event },
  {img: bloodymary},
]
