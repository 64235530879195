import React from 'react'

import About from './Components/About'
import Home from './Components/Home'
import Merit from './Components/Merit'
import Contact from './Components/Contact'
import Footer from './Components/Footer'
import Gallery from './Components/Gallery'

const App = () => {
  return (
    <>
      <Home />
      <About />
      <Merit />
      <Gallery />
      <Contact />
      <Footer />
    </>
  )
}

export default App
