import React from 'react'

const Merit = () => {
  return (
    <div className="merit-container">
      <ul className="merit">
        <li>32 års erfarenhet inom Bar och Resturang </li>
        <li>Drivit Barskola i 10 år</li>
        <li>Lång erfarenhet av utländska och svenska krogar </li>
        <li>Utbildad inom gastronomi och mixologi</li>
        <li>Vinnare av Guldshakern 2012</li>
        <li>Jobbat med internationella Spritmärken </li>
      </ul>
      <img
        className="nicke"
        src="https://i.imgur.com/Z09azht.jpg"
        alt="Nicke"
      />
    </div>
  )
}

export default Merit
