import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
  return (
    <footer>
      {/* <a
        href="https://facebook.https://www.facebook.com/nicke.edberg"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon
          icon={faFacebook}
          size="2x"
          style={{ color: 'white' }}
        />
      </a> */}
      <p className="instagram-text"> Följ oss på instagram ⤵ </p>

      <a
        href="https://instagram.com/nickeedberg?igshid=NTc4MTIwNjQ2YQ=="
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon
          icon={faInstagram}
          size="2x"
          style={{ color: 'white' }}
        />
      </a>
    </footer>
  )
}

export default Footer
