import React from 'react'
import drink_logo from './Assets/drink_logo.svg'
// import text from './Assets/text.svg'

const Home = () => {
  return (
    <section className="home">
      <img className="svg-logo" src={drink_logo} alt="React Logo" />
      {/* <img className="svg-text" src={text} alt="svg text" /> */}
    </section>
  )
}

export default Home
