import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'

const Contact = () => {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs.sendForm(
      'service_lwb7v1s',
      'template_ctcow5p',
      form.current,
      'ILjh3bnecloSJRHwh',
    )

    e.target.reset()
  }

  return (
    <section>
      <h2>Kontakt</h2>
      <div className="container contact_container">
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Namn" required />
          <input type="email" name="email" placeholder="Email" required />
          <textarea
            name="message"
            placeholder="Skriv en rad"
            required
            rows="7"
          />
          <button type="submit" className="button button-primary">
            Skicka
          </button>
        </form>
      </div>
    </section>
  )
}

export default Contact
