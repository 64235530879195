import React, { useState, useEffect } from 'react'
import { images } from './Images'

const Gallery = () => {
  const [currImg, setCurrImg] = useState(0)

  const nextImage = () => {
    setCurrImg((currImg) => (currImg < images.length - 1 ? currImg + 1 : 0))
  }

  useEffect(() => {
    const timer = setInterval(nextImage, 5000)
    return () => clearInterval(timer)
  }, [])

  return (
    <div id="gallery" className="carousel">
      <div
        className="carouselInner"
        style={{ backgroundImage: `url(${images[currImg].img})` }}
      ></div>
    </div>
  )
}

export default Gallery
